import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export default styled(PerfectScrollbar)`
	flex: 1 1 auto;
	margin-top: 20px;
	@media (min-width: 576px) {
		padding: 20px;
		box-shadow: 0 4px 64px 4px rgba(0, 0, 0, 0.15);
	}
`;
