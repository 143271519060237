import { Dropdown as StrapDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import styled from 'styled-components';

const Dropdown = styled(StrapDropdown)`
  display: inline-flex;

  button {
    height: 100% !important;
    background-color: ${(props) => (props.box ? props.theme.color.primaryLight : 'transparent')} !important;
    color: ${(props) => props.theme.color.default} !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    ${(props) => (props.box ? `padding-right: 6px !important; padding-left: 6px !important;` : '')};
  }

  div > button {
    background-color: transparent !important;
    color: ${(props) => props.theme.color.default} !important;
    outline: none !important;
    cursor: pointer !important;
    padding: 0 7px !important;
  }

  .dropdown-toggle::after {
    margin-left: 6px;
  }
`;

Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;

export default Dropdown;
