export default {
	color: {
		primary: 'rgb(32, 125, 169)',
		primaryLight: 'rgb(219, 230, 255)',
		secondary: '#3dbcf6',
		default: 'rgb(97, 97, 97)',
		dot: 'rgb(233, 197, 82)',
		action: 'rgb(77, 124, 254)'
	},
	field: {
		height: '52px',
		paddingHorizontal: '15px',
		borderColor: 'rgb(161, 161, 161)',
		focusBorderColor: 'rgb(0, 0, 0)',
		errorBorderColor: 'rgb(242, 108, 79)'
	}
};
