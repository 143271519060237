import styled from 'styled-components';

export default styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	color: rgb(97, 97, 97);
	> * {
		margin-bottom: 12px;
	}
	img {
		max-width: 50%;
		width: 250px;
	}
	p {
		font-size: 12px;
	}
	@media (min-width: 576px) {
		h1 {
			font-size: 64px;
		}
		p,
		button {
			font-size: 18px;
		}
	}
`;
