import { call, put, takeLatest } from 'redux-saga/effects';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from './actions';
import { post } from '../../utils/api';
import { GET_NOTES_SUCCESS } from '../NotesPage/actions';
import swal from 'sweetalert';
import ReactGA from 'react-ga';

function* login({ payload }) {
	try {
		const { data, headers } = yield call(post, `/users/login`, payload);
		yield put({
			type: LOGIN_SUCCESS,
			payload: { token: headers['x-auth-token'], email: data.email, lang: data.lang, vocabulary: data.vocabulary }
		});
		ReactGA.event({ category: 'dictation_web', action: 'login', label: data.email.replace('@', '[at]') });
		yield put({ type: GET_NOTES_SUCCESS, payload: JSON.parse(data.notes) });
	} catch (error) {
		swal(error.message);
		yield put({ type: LOGIN_FAILURE, payload: error.message });
	}
}

export default [ takeLatest(LOGIN_REQUEST, login) ];
