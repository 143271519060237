import styled from 'styled-components';
import { button, field } from '../../utils/css';

export default styled.button`
	${button};
	${field};
	background-color: ${(props) => props.theme.color.primary};
	color: white;
	font-family: 'Montserrat';
`;
