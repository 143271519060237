import styled from 'styled-components';
import { button } from '../../utils/css';

export default styled.button`
	${button};
	${(props) => (!props.noPadding ? 'padding: 8px 28px;' : 'padding: 8px 0;')};
	background-color: ${(props) => (props.primary ? props.theme.color.primary : 'transparent')};
	color: ${(props) => (props.primary ? '#ffffff' : props.theme.color.default)};
	border-radius: 3px;
	font-family: 'Montserrat';
	user-select: none;
`;
