import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { Helmet } from 'react-helmet';
// import swal from 'sweetalert';

import GuestBox from '../../components/GuestBox';
// import Link from '../../components/Link';
import TextField from '../../components/TextField';
import ButtonField from '../../components/ButtonField';
import { createLoadingSelector, tokenSelector } from '../Main/reducer';
import { LOGIN_REQUEST, login } from './actions';
import { emailValidator, passwordValidator } from '../../utils/validator';

class LoginPage extends React.Component {
	render() {
		const { /*history,*/ loading, login, handleSubmit } = this.props;
		return (
			<BlockUi blocking={loading}>
				<Helmet>
					<title>Login | VoiceboxMD Web</title>
					<meta
						name="description"
						content="Log in to create secure notes using speech to text recognition."
					/>
				</Helmet>
				<GuestBox>
					<div className="title mb-4">
						<FormattedMessage id="LoginPage.signIn" />
					</div>
					{/* <div className="mb-3 text-black">
						<FormattedMessage id="LoginPage.noAccount" />{' '}
						<Link primary bold onClick={() => history.push('/register')}>
							<FormattedMessage id="LoginPage.register" />
						</Link>
					</div> */}
					<FormattedMessage id="LoginPage.email">
						{(msg) => (
							<Field name="email" component={TextField} placeholder={msg} className="mb-3" type="email" />
						)}
					</FormattedMessage>
					<FormattedMessage id="LoginPage.password">
						{(msg) => (
							<Field
								name="password"
								component={TextField}
								type="password"
								placeholder={msg}
								className="mb-3"
								onEnter={handleSubmit(login)}
							/>
						)}
					</FormattedMessage>
					<ButtonField className="mb-3" loading={true} onClick={handleSubmit(login)}>
						<FormattedMessage id="LoginPage.signInSecondary" />
					</ButtonField>
					{/* <Link
						bold
						onClick={() => onClick={() => history.push('/forgot')}
						className="forgot"
					>
						<FormattedMessage id="LoginPage.forgot" />
					</Link> */}
				</GuestBox>
			</BlockUi>
		);
	}
}

LoginPage = reduxForm({
	form: 'login',
	validate: (values) => {
		const errors = {};
		if (!emailValidator(values.email)) errors.email = true;
		if (!passwordValidator(values.password)) errors.password = true;
		return errors;
	}
})(LoginPage);

LoginPage = connect(
	createStructuredSelector({
		token: tokenSelector,
		loading: createLoadingSelector([ LOGIN_REQUEST ])
	}),
	{ login }
)(LoginPage);

export default LoginPage;
