import React from 'react';
import Wrapper from './Wrapper';
import PureInput from '../../components/PureInput';
import MaterialIcon from 'material-icons-react';

export default class Field extends React.Component {
	render() {
		const { input: { value, onChange }, className, theme, ...rest } = this.props;
		return (
			<Wrapper className={`${className} flex-row`}>
				<div className="mr-2 d-none d-sm-block">
					<MaterialIcon icon="search" size={26} />
				</div>
				<div className="mr-1 d-sm-none">
					<MaterialIcon icon="search" size={22} />
				</div>
				<PureInput {...rest} value={value} onChange={onChange} className="flex-fill" />
			</Wrapper>
		);
	}
}
