import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
	GET_NOTES_REQUEST,
	GET_NOTES_SUCCESS,
	GET_NOTES_FAILURE,
	SAVE_NOTE_REQUEST,
	SAVE_NOTE_SUCCESS,
	SAVE_NOTE_FAILURE,
	DELETE_NOTES_REQUEST,
	DELETE_NOTES_SUCCESS,
	DELETE_NOTES_FAILURE
} from './actions';
import { goBack } from 'connected-react-router';
import { tokenSelector, emailSelector } from '../Main/reducer';
import { get, post, del } from '../../utils/api';
import { selectedIdsSelector } from './reducer';
import swal from 'sweetalert';
import ReactGA from 'react-ga';

function* getNotes() {
	try {
		const token = yield select(tokenSelector);
		const email = yield select(emailSelector);
		const { data } = yield call(get, `/users/${email}/notes`, token);
		yield put({ type: GET_NOTES_SUCCESS, payload: JSON.parse(data) });
	} catch (error) {
		yield put({ type: GET_NOTES_FAILURE, payload: error.message });
		swal(error.message);
	}
}

function* saveNote({ payload }) {
	try {
		const token = yield select(tokenSelector);
		const email = yield select(emailSelector);
		yield call(
			post,
			`/users/${email}/notes`,
			{ Id: payload.id, Text: payload.text, Title: payload.title, UtcUpdateDate: payload.date },
			token
		);
		yield put(goBack());
		yield put({ type: SAVE_NOTE_SUCCESS, payload });
		ReactGA.event({ category: 'dictation_web', action: 'note_save', label: email.replace('@', '[at]') });
	} catch (error) {
		yield put({ type: SAVE_NOTE_FAILURE, payload: error.message });
		swal(error.message);
	}
}

function* deleteNotes() {
	try {
		const token = yield select(tokenSelector);
		const email = yield select(emailSelector);
		const selectedIds = yield select(selectedIdsSelector);
		yield all(selectedIds.map((selectedId) => call(del, `/users/${email}/notes`, selectedId, token)));
		// for (const selectedId of selectedIds) yield call(del, `/users/${email}/notes`, selectedId, token);
		yield put({ type: DELETE_NOTES_SUCCESS });
	} catch (error) {
		yield put({ type: DELETE_NOTES_FAILURE, payload: error.message });
		swal(error.message);
	}
}

export default [
	takeLatest(GET_NOTES_REQUEST, getNotes),
	takeLatest(SAVE_NOTE_REQUEST, saveNote),
	takeLatest(DELETE_NOTES_REQUEST, deleteNotes)
];
