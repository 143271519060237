import { select, call, put, takeLatest } from 'redux-saga/effects';
import swal from 'sweetalert';
import { UPDATE_PASSWORD_REQUEST, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE, closeSettings } from './actions';
import { post } from '../../utils/api';
import { emailSelector, tokenSelector } from '../Main/reducer';

function* updatePassword({ payload }) {
	try {
		const email = yield select(emailSelector);
		const token = yield select(tokenSelector);
		if (payload.currentPassword)
			yield call(
				post,
				`/users/update-password`,
				{ email, currentPassword: payload.currentPassword, newPassword: payload.newPassword },
				token
			);
		const response = yield call(post, `/profile`, { lang: payload.lang, vocal: payload.vocabulary }, token);
		console.log(response);
		yield put(closeSettings());
		yield put({ type: UPDATE_PASSWORD_SUCCESS, payload });
	} catch (error) {
		swal(error.message);
		yield put({ type: UPDATE_PASSWORD_FAILURE, payload: error.message });
	}
}

export default [ takeLatest(UPDATE_PASSWORD_REQUEST, updatePassword) ];
