import styled from 'styled-components';
import GuestBox from '../../../components/GuestBox';

export default styled(GuestBox)`
  position: relative;
  background-color: white;
  text-align: left;
  box-shadow: 0 4px 64px 4px rgba(0, 0, 0, 0.15);
  max-width: 89%;
  width: 500px;
  padding: 15px;
  @media (min-width: 576px) {
    padding: 25px;
  }
`;
