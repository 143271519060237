import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { withTheme } from "styled-components";
import { Row, Col } from "reactstrap";
import Icon from "@mdi/react";
import {
  mdiSortAscending,
  mdiSortDescending,
  mdiDelete,
  mdiPlusCircle,
  mdiPencil,
  mdiVectorArrangeAbove,
  mdiClipboardOutline,
  mdiCircle,
} from "@mdi/js";
import Checkbox from "rc-checkbox";
import { tokenSelector } from "../Main/reducer";
import {
  toggleSelectAll,
  toggleSelectById,
  deleteNotes,
  updateSortBy,
  DELETE_NOTES_REQUEST,
} from "./actions";
import Button from "../../components/Button";
import {
  selectAllCheckedSelector,
  filteredNotesSelector,
  sortBySelector,
} from "./reducer";
import { createLoadingSelector } from "../Main/reducer";
import Notes from "./components/Notes";
import Note from "./components/Note";
import IconButton from "./components/IconButton";
import Search from "../Search";
import Dropdown from "../../components/Dropdown";
import BlockUi from "react-block-ui";
import NoNotes from "./components/NoNotes";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import { CopyToClipboard } from "react-copy-to-clipboard";
import striptags from "striptags";
import { toast } from "react-toastify";

class NotesPage extends React.Component {
  state = {
    sortByOpen: false,
  };

  renderSortBy = (sortBy) =>
    this.props.sortBy === sortBy ? (
      <Icon
        path={mdiSortAscending}
        size={0.8}
        color={this.props.theme.color.default}
      />
    ) : this.props.sortBy === `-${sortBy}` ? (
      <Icon
        path={mdiSortDescending}
        size={0.8}
        color={this.props.theme.color.default}
      />
    ) : (
      ""
    );

  render() {
    const {
      theme,
      history,
      filteredNotes,
      selectAllChecked,
      loading,
      toggleSelectAll,
      toggleSelectById,
      deleteNotes,
      updateSortBy,
    } = this.props;
    const { sortByOpen } = this.state;
    return (
      <BlockUi
        blocking={loading}
        className="d-flex flex-column flex-fill"
        style={{ marginRight: "-20px" }}
      >
        <Helmet>
          <title>Secure voice notes | VoiceboxMD Web</title>
          <meta
            name="description"
            content="Create secure notes using speech to text recognition."
          />
        </Helmet>
        <Search
          className="d-flex d-sm-none mb-2 mb-md-4"
          style={{ flex: "0 0" }}
        />
        <div
          style={{ marginRight: "20px", flex: "0 0 auto" }}
          className="d-flex align-items-center"
        >
          <div className="mr-3 d-inline-flex align-items-center">
            <Checkbox checked={selectAllChecked} onChange={toggleSelectAll} />
            <div className="ml-1 pl-1">
              <FormattedMessage id="NotesPage.selectAll" />
            </div>
          </div>
          <Button onClick={deleteNotes} noPadding className="mr-3">
            <Icon
              path={mdiDelete}
              size={1}
              color={theme.color.default}
              style={{ marginTop: "-4px" }}
            />
            <div className="ml-1">
              <FormattedMessage id="NotesPage.deleteNote" />
            </div>
          </Button>
          <Dropdown
            isOpen={sortByOpen}
            toggle={() => this.setState({ sortByOpen: !sortByOpen })}
            box="box"
          >
            <Dropdown.Toggle caret>
              <FormattedMessage id="NotesPage.sortBy" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateSortBy("date")}>
                <FormattedMessage id="NotesPage.date" />{" "}
                {this.renderSortBy("date")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateSortBy("title")}>
                <FormattedMessage id="NotesPage.title" />{" "}
                {this.renderSortBy("title")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateSortBy("text")}>
                <FormattedMessage id="NotesPage.text" />{" "}
                {this.renderSortBy("text")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
      <div className="ml-auto">
        <Button
           className="mr-5"
           noPadding
           onClick={() => history.push("/uploadfile")}
         >
           <Icon path={mdiPlusCircle} size={1} color={theme.color.action} />
           <div
             className="ml-2 d-none d-sm-block"
             style={{ color: theme.color.action }}
           >
             <FormattedMessage id="NotesPage.uploadFile" />
           </div>
        </Button>
       
        <Button
           
           noPadding
           onClick={() => history.push("/edit")}
         >
           <Icon path={mdiPlusCircle} size={1} color={theme.color.action} />
           <div
             className="ml-2 d-none d-sm-block"
             style={{ color: theme.color.action }}
           >
             <FormattedMessage id="NotesPage.addNote" />
           </div>
        </Button>     
      </div>
          
        </div>
        {filteredNotes && filteredNotes.length > 0 && (
          <Notes className="flex-grow-1">
            <Row noGutters style={{ marginTop: "-17px" }}>
              {filteredNotes.map((note) => (
                <Col xl="3" lg="4" sm="6" key={note["id"]}>
                  <Note
                    selected={note.selected}
                    onClick={() => history.push(`/view/${note.id}`)}
                  >
                    <Note.Action onClick={(e) => e.stopPropagation()}>
                      <IconButton onClick={() => toggleSelectById(note.id)}>
                        <Icon
                          path={mdiVectorArrangeAbove}
                          size={1}
                          color={theme.color.default}
                        />
                      </IconButton>
                      <CopyToClipboard
                        text={striptags(note.text.split("</p>").join("\n"))}
                        onCopy={() => toast.info(`Note ${note.title} copyed!`)}
                      >
                        <IconButton className="ml-2">
                          <Icon
                            path={mdiClipboardOutline}
                            size={1}
                            color={theme.color.default}
                          />
                        </IconButton>
                      </CopyToClipboard>
                      <IconButton
                        onClick={() => history.push(`/edit/${note.id}`)}
                        className="ml-2"
                      >
                        <Icon
                          path={mdiPencil}
                          size={1}
                          color={theme.color.default}
                        />
                      </IconButton>
                    </Note.Action>
                    <p>{note["date"]}</p>
                    <Note.Title>
                      <Icon
                        path={mdiCircle}
                        size={0.4}
                        color={theme.color.dot}
                      />
                      <span className="ml-2 pl-1">{note["title"]}</span>
                    </Note.Title>
                    <Note.Text
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: note["text"] }}
                    />
                  </Note>
                </Col>
              ))}
            </Row>
          </Notes>
        )}
        {(filteredNotes == null || filteredNotes.length === 0) && (
          <NoNotes>
            <img alt="no-notes" src={require("../../assets/no-notes.png")} />
            <h1>
              <FormattedMessage id="NotesPage.noNotes.title" />
            </h1>
            <div>
              <p className="mb-0">
                <FormattedMessage id="NotesPage.noNotes.desc1" />
              </p>
              <p>
                <FormattedMessage id="NotesPage.noNotes.desc2" />
              </p>
            </div>
            <Button primary onClick={() => history.push("/edit")}>
              <FormattedMessage id="NotesPage.addNote" />
            </Button>
          </NoNotes>
        )}
      </BlockUi>
    );
  }
}

NotesPage = withTheme(NotesPage);

NotesPage = connect(
  createStructuredSelector({
    token: tokenSelector,
    selectAllChecked: selectAllCheckedSelector,
    filteredNotes: filteredNotesSelector,
    sortBy: sortBySelector,
    loading: createLoadingSelector([DELETE_NOTES_REQUEST]),
  }),
  { toggleSelectAll, toggleSelectById, deleteNotes, updateSortBy }
)(NotesPage);

export default NotesPage;
