import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Route } from "react-router";
import { FormattedMessage } from "react-intl";
import { ToastContainer, Zoom } from "react-toastify";

import Dropdown from "../../components/Dropdown";
import RegisterPage from "../RegisterPage";
import NoteEditPage from "../NoteEditPage";
import LoginPage from "../LoginPage";
import ForgotPage from "../ForgotPage";
import NotesPage from "../NotesPage";
import NotePage from "../NotePage";
import UploadFile from "../UploadFile";
import Search from "../Search";
import Settings from "../Settings";
import { isOpenSelector } from "../Settings/reducer";
import { openSettings } from "../Settings/actions";

import Navbar from "./components/Navbar";
import Logo from "./components/Logo";
import Content from "./components/Content";
import { tokenSelector, emailSelector, pathnameSelector } from "./reducer";
import { logout } from "./actions";
import { getNotes } from "../NotesPage/actions";

class Main extends React.Component {
  state = {
    menuOpen: false,
  };
  componentDidMount() {
    const { getNotes } = this.props;
    const { token, pathname, history } = this.props;
    if (token != null) getNotes();
    if (
      token == null &&
      (pathname === "/" ||
        pathname.includes("edit") ||
        pathname.includes("view"))
    )
      history.replace("/login");
    if (token != null && ["/login", "/register", "/forgot"].includes(pathname))
      history.replace("/");
  }
  componentDidUpdate() {
    const { token, pathname, history } = this.props;
    if (
      token == null &&
      (pathname === "/" ||
        pathname.includes("edit") ||
        pathname.includes("view"))
    )
      history.replace("/login");
    if (token != null && ["/login", "/register", "/forgot"].includes(pathname))
      history.replace("/");
  }
  render() {
    const { token, email, logout, history, openSettings, isOpen } = this.props;
    const { menuOpen } = this.state;
    return (
      <React.Fragment>
        <Navbar>
          <Logo
            src={require("../../assets/vb-logo.png")}
            onClick={() => history.push("/")}
          />
          {token && (
            <React.Fragment>
              <Search className="ml-5 d-none d-sm-flex" />
              <div className="ml-auto">
                <Dropdown
                  isOpen={menuOpen}
                  toggle={() => this.setState({ menuOpen: !menuOpen })}
                >
                  <Dropdown.Toggle caret>{email}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={openSettings}>
                      <FormattedMessage id="Main.settings" />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>
                      <FormattedMessage id="Main.logout" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </React.Fragment>
          )}
        </Navbar>
        <Content>
          <Route exact path="/" component={NotesPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/forgot" component={ForgotPage} />
          <Route exact path="/edit/:id?" component={NoteEditPage} />
          <Route exact path="/view/:id" component={NotePage} />
          <Route exact path="/uploadfile" component={UploadFile} />
        </Content>
        {isOpen && <Settings />}
        <ToastContainer autoClose={2000} hideProgressBar transition={Zoom} />
      </React.Fragment>
    );
  }
}

Main = connect(
  createStructuredSelector({
    token: tokenSelector,
    email: emailSelector,
    pathname: pathnameSelector,
    isOpen: isOpenSelector,
  }),
  { logout, openSettings, getNotes }
)(Main);

export default Main;
