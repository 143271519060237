
import { getSignUrlApi, getUploadsListApi, putAudioFileApi } from '../../utils/api';


async function getSignedUrl(name, email, token, fileType) {
	try {
		let url = `https://us-central1-voiceboxmd-206315.cloudfunctions.net/utils-generate-signedurl?email=${email}&fileName=${name}&fileType=${fileType}`
		let response = await getSignUrlApi(url, token);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

async function putAudioFile(url, data){
	try {
		let response = await putAudioFileApi(url, data, {'Content-Type': data.type});
		return response.status;
	} catch (error) {
		console.error(error);
	}
}

async function getUploadsList(email, token){
	try {
		let url = `https://us-central1-voiceboxmd-206315.cloudfunctions.net/vb-v2-user-operations?email=${email}`
		let response = await getUploadsListApi(url, token);
		return response;
	} catch (error) {
		console.error(error);
	}
}

export { getSignedUrl, putAudioFile, getUploadsList };
