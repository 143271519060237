import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
	DELETE_NOTE_SUCCESS,
	DELETE_NOTE_REQUEST,
	DELETE_NOTE_FAILURE,
	AUTO_SAVE_NOTE_SUCCESS,
	AUTO_SAVE_NOTE_FAILURE,
	AUTO_SAVE_NOTE_REQUEST
} from './actions';
import { push } from 'connected-react-router';
import { tokenSelector, emailSelector } from '../Main/reducer';
import { del, post, getSignUrlApi, putAudioFileApi } from '../../utils/api';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

function* autoSaveNote({ payload }) {
	try {
		const token = yield select(tokenSelector);
		const email = yield select(emailSelector);
		yield call(
			post,
			`/users/${email}/notes`,
			{ Id: payload.id, Text: payload.text, Title: payload.title, UtcUpdateDate: payload.date },
			token
		);

		yield put({ type: AUTO_SAVE_NOTE_SUCCESS, payload });
		toast.info(`Note ${payload.title} autosaved!`);
	} catch (error) {
		yield put({ type: AUTO_SAVE_NOTE_FAILURE, payload: error.message });
		swal(error.message);
	}
}

function* deleteNote({ payload }) {
	try {
		const token = yield select(tokenSelector);
		const email = yield select(emailSelector);
		yield call(del, `/users/${email}/notes`, payload, token);
		yield put({ type: DELETE_NOTE_SUCCESS, payload });
		yield put(push('/'));
	} catch (error) {
		yield put({ type: DELETE_NOTE_FAILURE, payload: error.message });
		swal(error.message);
	}
}

async function getSignedUrl(name) {
	try {
		let url = `https://us-central1-voiceboxmd-206315.cloudfunctions.net/utils-generate-signedurl?bucket=vb_mac_upload&file=${name}`
		let response = await getSignUrlApi(url);
		return response.data;
	} catch (error) {
		console.error(error);
	}
}

async function putAudioFile(url, data){
	try {
		let response = await putAudioFileApi(url, data, {'Content-Type': 'audio/mpeg'});
		return response.status;
	} catch (error) {
		console.error(error);
	}
}

export { getSignedUrl, putAudioFile };

export default [ takeLatest(DELETE_NOTE_REQUEST, deleteNote), takeLatest(AUTO_SAVE_NOTE_REQUEST, autoSaveNote)];
