import styled from 'styled-components';

export default styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 500px;
  max-width: 100%;
  height: 43px;
  margin-right: 20px;
  margin-left: -10px;
  flex: 0 0 auto;
`;
