import uuid from 'uuid/v1';
import moment from 'moment';

export const DELETE_NOTE_REQUEST = 'DELETE_NOTE_REQUEST';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE';

export const deleteNote = (id) => ({
	type: DELETE_NOTE_REQUEST,
	payload: id
});

export const AUTO_SAVE_NOTE_REQUEST = 'AUTO_SAVE_NOTE_REQUEST';
export const AUTO_SAVE_NOTE_SUCCESS = 'AUTO_SAVE_NOTE_SUCCESS';
export const AUTO_SAVE_NOTE_FAILURE = 'AUTO_SAVE_NOTE_FAILURE';

export const autoSaveNote = (id, text, title) => {
	return {
		type: AUTO_SAVE_NOTE_REQUEST,
		payload: {
			id: id == null ? uuid() : id,
			date: moment().format('YYYY-MM-DD'),
			text,
			title
		}
	};
};
