import React from 'react';
import ReactQuill from 'react-quill';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Wrapper from './Wrapper';
import Icon from '@mdi/react';
import { mdiMicrophone } from '@mdi/js';
import Button from '../Button';
import SpeechRecognition from 'react-speech-recognition';
import swal from 'sweetalert';
import { debounce } from 'throttle-debounce';

class QuillField extends React.Component {
  state = {
    isSpeech: false,
    prevTranscript: ''
  };
  speechCompare = () => {
    const { transcript } = this.props;
    const { prevTranscript } = this.state;
    if (prevTranscript !== transcript) {
      this.speechSplit();
      this.setState({ prevTranscript: transcript || prevTranscript });
    }
  };
  speechSplit = debounce(800, () => {
    const { transcript } = this.props;

    console.log(transcript);

    const editor = this.reactQuillRef.getEditor();
    const { index, length } = editor.getSelection(true);
    editor.deleteText(index, length);

    const prev = index > 0 ? editor.getText(index - 1, 1) : '';

    editor.insertText(
      index,
      (([ '', '\n' ].includes(prev) ? '' : ' ') +
        ([ '', '\n', '.' ].includes(prev) ? transcript.charAt(0).toUpperCase() + transcript.slice(1) : transcript))
        .replace('new line', '\n')
        .replace('new paragraph', '\n\n')
    );
    this.props.resetTranscript();
  });
  componentWillMount() {
    if (this.interval) clearInterval(this.interval);
  }
  render() {
    const {
      input: { value, onChange },
      onMicClick,
      resetTranscript,
      transcript,
      startListening,
      stopListening,
      speech,
      speechOn,
      speechOff,
      ...rest
    } = this.props;
    return (
      <Wrapper>
        <div id="toolbar">
          <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
          </span>
          <span className="ql-formats">
            <button className="ql-align" />
            <button className="ql-align" value="center" />
            <button className="ql-align" value="right" />
            <button className="ql-align" value="justify" />
          </span>
          <span className="ql-formats">
            <button className="ql-link" />
          </span>
          <span className="ql-formats">
            <Button
              noPadding
              onClick={() => {
                if (this.state.isSpeech) {
                  resetTranscript();
                  stopListening();
                  clearInterval(this.interval);
                  speechOff();
                  this.setState({ isSpeech: false, prevTranscript: '' });
                } else {
                  if (speech) swal('Please turn off other recording');
                  else {
                    this.interval = setInterval(this.speechCompare, 200);
                    this.reactQuillRef.getEditor().focus();
                    speechOn();
                    this.setState({ isSpeech: true });
                    startListening();
                  }
                }
              }}
            >
              <Icon path={mdiMicrophone} size={1} color={this.state.isSpeech ? '#d44' : '#444'} />
            </Button>
          </span>
          <span className="ql-formats" style={{ color: '#d44' }}>
            {this.state.prevTranscript && this.state.isSpeech && `${this.state.prevTranscript} ...`}
          </span>
        </div>
        <PerfectScrollbar>
          <ReactQuill
            ref={ref => {
              this.reactQuillRef = ref;
            }}
            value={value}
            onChange={onChange}
            modules={{ toolbar: { container: '#toolbar' } }}
            {...rest}
          />
        </PerfectScrollbar>
      </Wrapper>
    );
  }
}
export default SpeechRecognition({ autoStart: false })(QuillField);
