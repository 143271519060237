import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { notesSelector } from '../../containers/NotesPage/reducer';

export const matchSelector = createMatchSelector({ path: '/view/:id' });

export const titleSelector = createSelector(
	matchSelector,
	notesSelector,
	(match, notes) => (notes[match.params.id] ? notes[match.params.id].title : '')
);

export const textSelector = createSelector(
	matchSelector,
	notesSelector,
	(match, notes) => (notes[match.params.id] ? notes[match.params.id].text : '')
);
