export default {
  "LoginPage.signIn": "Sign In",
  "LoginPage.noAccount": "No account?",
  "LoginPage.register": "Register Now",
  "LoginPage.signInSecondary": "SIGN IN",
  "LoginPage.email": "E-mail",
  "LoginPage.password": "Password",
  "LoginPage.forgot": "Forgot your password?",

  "RegisterPage.registered": "Already registered?",
  "RegisterPage.login": "Login now",
  "RegisterPage.email": "E-mail",
  "RegisterPage.password": "Password",
  "RegisterPage.repeatPassword": "Repeat Password",
  "RegisterPage.register": "CREATE AN ACCOUNT",

  "ForgotPage.title": "Forgot password?",
  "ForgotPage.desc1": "Enter your",
  "ForgotPage.desc2": "e-mail",
  "ForgotPage.next": "NEXT",
  "ForgotPage.return": "Return to sign in",

  "Main.settings": "Settings",
  "Main.logout": "Logout",

  "NoteEditPage.cancel": "Cancel",
  "NoteEditPage.save": "Save",
  "NoteEditPage.title": "Title",
  "NoteEditPage.text": "Enter note text here",

  "NotePage.editNote": "Edit note",
  "NotePage.copyNote": "Copy note",
  "NotePage.goBack": "Go back",

  "NotesPage.selectAll": "Select all",
  "NotesPage.deleteNote": "Delete note",
  "NotesPage.sortBy": "Sort by",
  "NotesPage.date": "Date",
  "NotesPage.title": "Title",
  "NotesPage.text": "Text",
  "NotesPage.addNote": "Add new note",
  "NotesPage.uploadFile": "Upload File",
  "NotesPage.noNotes.title": "No notes yet",
  "NotesPage.noNotes.desc1":
    "Oh no! Seems like you didn't create any notes yet.",
  "NotesPage.noNotes.desc2": "Start by pressing the button.",

  "Search.placeholder": "Search",

  "Settings.currentPassword": "Current password",
  "Settings.newPassword": "New password",
  "Settings.repeatPassword": "Repeat password",
  "Settings.changePassword": "Change password",
  "Settings.language": "Language",
  "Settings.vocabulary": "Vocabulary",

  "UploadFile.note": "Title",
};
