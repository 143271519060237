import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import BlockUi from 'react-block-ui';
import GuestBox from '../../components/GuestBox';
import Link from '../../components/Link';
import TextField from '../../components/TextField';
import ButtonField from '../../components/ButtonField';
import { createLoadingSelector, tokenSelector } from '../Main/reducer';
import { REGISTER_REQUEST, register } from './actions';
import { FormattedMessage } from 'react-intl';
import { emailValidator, passwordValidator, repeatPasswordValidator } from '../../utils/validator';
import { Helmet } from 'react-helmet';

class RegisterPage extends React.Component {
	render() {
		const { history, loading, register, handleSubmit } = this.props;
		return (
			<BlockUi tagname="div" blocking={loading}>
				<Helmet>
					<title>Login | VoiceboxMD Web</title>
					<meta
						name="description"
						content="Register to create secure notes using speech to text recognition."
					/>
				</Helmet>
				<GuestBox>
					<div className="title mb-4">Register</div>
					<div className="text-black mb-3" onClick={() => history.push('/login')}>
						<FormattedMessage id="RegisterPage.registered" />{' '}
						<Link primary bold>
							<FormattedMessage id="RegisterPage.login" />
						</Link>
					</div>
					<FormattedMessage id="RegisterPage.email">
						{(msg) => (
							<Field name="email" component={TextField} type="email" placeholder={msg} className="mb-3" />
						)}
					</FormattedMessage>
					<FormattedMessage id="RegisterPage.password">
						{(msg) => (
							<Field
								name="password"
								component={TextField}
								type="password"
								placeholder={msg}
								className="mb-3"
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id="RegisterPage.repeatPassword">
						{(msg) => (
							<Field
								name="repeatPassword"
								component={TextField}
								type="password"
								placeholder={msg}
								className="mb-3"
								onEnter={handleSubmit(register)}
							/>
						)}
					</FormattedMessage>
					<ButtonField className="mb-3" onClick={handleSubmit(register)}>
						<FormattedMessage id="RegisterPage.register" />
					</ButtonField>
				</GuestBox>
			</BlockUi>
		);
	}
}

RegisterPage = reduxForm({
	form: 'register',
	validate: (values) => {
		const errors = {};
		if (!emailValidator(values.email)) errors.email = true;
		if (!passwordValidator(values.password)) errors.password = true;
		if (!repeatPasswordValidator(values.repeatPassword, values.password)) errors.repeatPassword = true;
		return errors;
	}
})(RegisterPage);

RegisterPage = connect(
	createStructuredSelector({
		token: tokenSelector,
		loading: createLoadingSelector([ REGISTER_REQUEST ])
	}),
	{ register }
)(RegisterPage);

export default RegisterPage;
