import styled from 'styled-components';
import { button } from '../../../utils/css';

export default styled.div`
	${button};
	width: 27px;
	height: 27px;
	border-radius: 5px;
	background-color: #ffffff;
`;
