import styled from 'styled-components';

export default styled.div`
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	margin-top: 20px;
	@media (min-width: 576px) {
		box-shadow: 0 4px 64px 4px rgba(0, 0, 0, 0.15);
	}
`;
