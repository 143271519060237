import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { tokenSelector, emailSelector, titleSelector } from "./reducer";
import { withTheme } from "styled-components";
import { reduxForm } from "redux-form";
import TitleBox from "./components/TitleBox";
import TextField from "../../components/TextField";
import { FormattedMessage } from "react-intl";
import "./uploadFile.css";
import { getSignedUrl, getUploadsList, putAudioFile } from "./sagas";
class UploadFile extends React.Component {
  state = {
    title: "",
    loading: false,
    uploadList: [],
  };

  onFileClick = (event) => {
    if (!this.state.title || this.state.title == "") {
      alert("Please add title!");
      event.preventDefault();
    }
  };

  onFileChange = async (e) => {
    try {
      this.setState({ ...this.state, loading: true });
      const file = e.target.files[0];
      let last_dot = file.name.lastIndexOf('.')
      let ext = file.name.slice(last_dot + 1)
      let name = `C0L0N${this.props.email}C0L0N${this.props.token}C0L0N${this
        .state.title +
        "___" +
        new Date().getTime()}C0L0N.${ext}`;

      try {
        let blob = file.slice(0, file.size, file.type);
        let newFile = new File([blob], name, { type: file.type });
        let signedUrl = await getSignedUrl(
          name,
          this.props.email,
          this.props.token,
          file.type
        );
        let response = await putAudioFile(signedUrl, newFile);
        this.setState({ ...this.state, loading: false, title: "" });
        this.props.change('edit', 'title', '');
        alert('Your audio has been uploaded successfully. Please come back in 15 minutes to review the transcription.');
      } catch (error) {
        console.error(error);
        this.setState({ ...this.state, loading: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({ ...this.state, loading: false });
    }
  };

  componentDidMount() {
    this.getInitialData();
    this.setState({ ...this.state, title: "" });
  }

  getInitialData = async () => {
    let response = await getUploadsList(this.props.email, this.props.token);
    if (response && response.status == 200) {
      this.setState({ ...this.state, uploadList: response.data });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ minHeight: "90vh" }}>
          <h4 className="text-center">Upload audio file for transcription</h4>
          <TitleBox className="mb-5">
            <FormattedMessage id="UploadFile.note">
              {(msg) => (
                <input
                  className='title-input'
                  name="title"
                  placeholder={msg}
                  type='text'
                  value={this.state.title}
                  onChange={(event) =>
                    this.setState({ ...this.state, title: event.target.value })
                  }
                />
              )}
            </FormattedMessage>
          </TitleBox>
          <div className="d-flex justify-content-center mb-3">
            <label className="custom-file-upload m-auto">
              <input
                id="files"
                type="file"
                accept="audio/*"
                disabled={this.state.loading}
                onChange={this.onFileChange}
                onClick={this.onFileClick}
              />
              {this.state.loading ? "Please wait..." : "Select Audio File"}
            </label>
          </div>
          <h5 className="text-center mb-5">
            Select an audio file to transcribe.Max <br /> audio length of 5
            minutes.
          </h5>
          <table className="w-100 table">
            <thead>
              <th className="w-50">Title</th>
              <th>Date</th>
              <th>Status</th>
            </thead>
            {this.state.uploadList.map((ele, i) => {
              return (
                <tr>
                  <td
                    onClick={() =>
                      ele.status == "done"
                        ? this.props.history.push(`/view/${ele.noteId}`)
                        : null
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {ele.name || "N/A"}
                  </td>
                  <td>{ele.date.split(",")[0]}</td>
                  <td>{ele.status || "N/A"}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

UploadFile = reduxForm({ form: "edit" })(UploadFile);

UploadFile = withTheme(UploadFile);

UploadFile = connect(
  createStructuredSelector({
    token: tokenSelector,
    email: emailSelector,
    title: titleSelector,

  }),
  {}
)(UploadFile);

export default UploadFile;
