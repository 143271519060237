import { css } from 'styled-components';

export const button = css`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	&:focus {
		outline: none;
	}
	&:hover {
		opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
	}
`;

export const field = css`
	height: ${(props) => props.theme.field.height};
	padding: 0 ${(props) => props.theme.field.paddingHorizontal};
	width: 100%;
	display: flex;
	justify-content: center;
`;
