import {
	TOOGLE_SELECT_ALL,
	TOGGLE_SELECT_BY_ID,
	GET_NOTES_SUCCESS,
	SAVE_NOTE_SUCCESS,
	DELETE_NOTES_SUCCESS,
	UPDATE_SORT_BY
} from './actions';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { normalize, schema } from 'normalizr';
import moment from 'moment';
import { DELETE_NOTE_SUCCESS, AUTO_SAVE_NOTE_SUCCESS } from '../NoteEditPage/actions';

const initialState = {
	selectAllChecked: false,
	notes: {},
	selectedIds: [],
	sortBy: 'date'
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case TOOGLE_SELECT_ALL: {
			const selectedIds = [];
			!state.selectAllChecked && Object.keys(state.notes).forEach((id) => selectedIds.push(id));
			return { ...state, selectAllChecked: !state.selectAllChecked, selectedIds };
		}
		case TOGGLE_SELECT_BY_ID: {
			const selectedIds = [ ...state.selectedIds ];
			const index = selectedIds.indexOf(payload);
			index !== -1 ? selectedIds.splice(index, 1) : selectedIds.push(payload);
			return { ...state, selectedIds, selectAllChecked: Object.keys(state.notes).length === selectedIds.length };
		}
		case GET_NOTES_SUCCESS: {
			const notes = payload.map(({ Id, Text, Title, UtcUpdateDate }) => ({
				id: Id,
				title: Title,
				text: Text,
				date: UtcUpdateDate
			}));
			const noteSchema = new schema.Entity('notes');
			const normalizedNotes = normalize(notes, [ noteSchema ]);
			return {
				...state,
				notes: normalizedNotes.entities.notes || {}
			};
		}
		case DELETE_NOTES_SUCCESS: {
			const notes = { ...state.notes };
			state.selectedIds.forEach((id) => {
				delete notes[id];
			});
			return { ...state, notes, selectedIds: [] };
		}
		case DELETE_NOTE_SUCCESS: {
			const { [payload]: value, ...newNotes } = state.notes;
			return { ...state, notes: newNotes, selectedIds: state.selectedIds.filter((id) => id !== payload) };
		}
		case SAVE_NOTE_SUCCESS:
		case AUTO_SAVE_NOTE_SUCCESS: {
			const notes = { ...state.notes };
			notes[payload.id] = payload;
			return { ...state, notes };
		}
		case UPDATE_SORT_BY: {
			return { ...state, sortBy: state.sortBy === payload ? `-${payload}` : payload };
		}
		default:
			return state;
	}
};

export const selectAllCheckedSelector = (state) => state.notesPage.selectAllChecked;

export const notesSelector = (state) => state.notesPage.notes;

export const selectedIdsSelector = (state) => state.notesPage.selectedIds;

export const sortBySelector = (state) => state.notesPage.sortBy;

const searchSelector = (state) => (formValueSelector('search')(state, 'search') || '').toLowerCase();

const stringCompare = (a, b) => (a > b ? 1 : b > a ? -1 : 0);

export const filteredNotesSelector = createSelector(
	notesSelector,
	selectedIdsSelector,
	sortBySelector,
	searchSelector,
	(notes, selectedIds, sortBy, search) =>
		notes &&
		Object.entries(notes)
			.map(([ id, note ]) =>
				Object.assign(
					{
						id: note.id,
						date: moment(note.date).format('D MMMM, YYYY'),
						title: note.title || '',
						text: note.text || ''
					},
					selectedIds.includes(id) ? { selected: true } : { selected: false }
				)
			)
			.filter(
				({ title, date, text }) =>
					title.toLowerCase().includes(search) ||
					date.toLowerCase().includes(search) ||
					text.toLowerCase().includes(search)
			)
			.sort((a, b) => {
				return sortBy.includes('-')
					? stringCompare(b[sortBy.substring(1)].toLowerCase(), a[sortBy.substring(1)].toLowerCase())
					: stringCompare(a[sortBy].toLowerCase(), b[sortBy].toLowerCase());
			})
);
