import ReactSelect from 'react-select';
import styled from 'styled-components';
import { withProps } from 'recompose';

export default withProps({ classNamePrefix: 'select' })(styled(ReactSelect)`
	background-color: #efefef !important;
  height: 40px !important;
  .select__control {
    border: 1px solid ${(props) => props.theme.field.borderColor};
    box-shadow: none;
    border-radius: 0;
    background-color: transparent !important;
  }
  .select__control:hover {
    border: 1px solid ${(props) => props.theme.field.borderColor};
  }
  .select__indicators {
  }
  .select__indicator-separator {
    background-color: transparent;
    height: 0;
  }
  .select__indicator {
    color: ${(props) => props.theme.field.borderColor} !important;
    padding: 0;
    padding-right: 8px;
  }
  .select__value-container {
    padding: 0 0 0 16px;
  }
  .select__value-container div {
    margin: 0;
  }
  .select__placeholder {
    margin: 0;
  }
`);
