import React from "react";
import createSagaMiddleware from "redux-saga";
import { combineReducers, applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter,
} from "connected-react-router";
import { createHashHistory } from "history";
import { all } from "redux-saga/effects";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import { ThemeProvider } from "styled-components";
import { Switch, Route } from "react-router";
import { reducer as formReducer } from "redux-form";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import Main from "./containers/Main";
import mainReducer from "./containers/Main/reducer";
import LanguageProvider from "./components/LanguageProvider";
import theme from "./utils/theme";
import settingsReducer from "./containers/Settings/reducer";
import notesPageReducer from "./containers/NotesPage/reducer";
import registerPageSagas from "./containers/RegisterPage/sagas";
import loginPageSagas from "./containers/LoginPage/sagas";
import notesPageSagas from "./containers/NotesPage/sagas";
import noteEditPageSagas from "./containers/NoteEditPage/sagas";
import settingsSagas from "./containers/Settings/sagas";
import ReactGA from "react-ga";
import config from "./config";
import "react-toastify/dist/ReactToastify.css";

ReactGA.initialize(config.gaId);
const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  router: connectRouter(history),
  form: formReducer,
  main: mainReducer,
  notesPage: notesPageReducer,
  settings: settingsReducer,
});
const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    transforms: [
      createBlacklistFilter("main", "speech"),
      createBlacklistFilter("main", "loadings"),
      createBlacklistFilter("form", "login"),
      createBlacklistFilter("form", "register"),
      createBlacklistFilter("form", "forgot"),
      createBlacklistFilter("form", "settings"),
    ],
  },
  rootReducer
);

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
);
let persistor = persistStore(store);

function* rootSaga() {
  yield all([
    ...registerPageSagas,
    ...loginPageSagas,
    ...notesPageSagas,
    ...settingsSagas,
    ...noteEditPageSagas,
  ]);
}
sagaMiddleware.run(rootSaga);

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LanguageProvider>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <Switch>
              <Route path="/" component={Main} />
            </Switch>
          </ThemeProvider>
        </ConnectedRouter>
      </LanguageProvider>
    </PersistGate>
  </Provider>
);

export default App;
