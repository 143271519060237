import { call, put, takeLatest } from 'redux-saga/effects';
import { REGISTER_REQUEST, REGISTER_FAILURE, REGISTER_SUCCESS } from './actions';
import { post } from '../../utils/api';
import swal from 'sweetalert';

function* register({ payload }) {
	try {
		const { data, headers } = yield call(post, `/users`, payload);
		yield put({ type: REGISTER_SUCCESS, payload: { token: headers['x-auth-token'], email: data.email } });
	} catch (error) {
		swal(error.message);
		yield put({ type: REGISTER_FAILURE, payload: error.message });
	}
}

export default [ takeLatest(REGISTER_REQUEST, register) ];
