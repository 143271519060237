import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { reduxForm, Field } from 'redux-form';
import { withTheme } from 'styled-components';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import Overlay from './components/Overlay';
import Modal from './components/Modal';
import { closeSettings, updatePassword, UPDATE_PASSWORD_REQUEST } from './actions';
import BlockUi from 'react-block-ui';
import { createLoadingSelector, langSelector, vocabularySelector } from '../Main/reducer';
import { FormattedMessage } from 'react-intl';
import { passwordValidator, repeatPasswordValidator, validator } from '../../utils/validator';
import SelectField from '../../components/SelectField';
import config from '../../config';

class Settings extends React.Component {
	render() {
		const { theme, loading, closeSettings, handleSubmit, updatePassword } = this.props;
		return (
			<Overlay
				onClick={() => {
					if (!loading) closeSettings();
				}}
			>
				<Modal onClick={(e) => e.stopPropagation()}>
					<BlockUi blocking={loading}>
						<h3 className="mb-4">Settings</h3>
						<Button
							noPadding
							style={{ position: 'absolute', right: '5px', top: '5px' }}
							onClick={closeSettings}
						>
							<Icon path={mdiClose} size={1} color={theme.color.default} />
						</Button>
						<FormattedMessage id="Settings.currentPassword">
							{(msg) => (
								<Field
									name="currentPassword"
									component={TextField}
									type="password"
									placeholder={msg}
									className="mb-3"
									secondary
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id="Settings.newPassword">
							{(msg) => (
								<Field
									name="newPassword"
									component={TextField}
									type="password"
									placeholder={msg}
									className="mb-3"
									secondary
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id="Settings.repeatPassword">
							{(msg) => (
								<Field
									name="repeatPassword"
									component={TextField}
									type="password"
									placeholder={msg}
									className="mb-3"
									secondary
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id="Settings.language">
							{(msg) => (
								<Field
									name="lang"
									component={SelectField}
									options={config.langOptions}
									placeholder={msg}
									className="mb-3"
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id="Settings.vocabulary">
							{(msg) => (
								<Field
									name="vocabulary"
									component={TextField}
									placeholder={msg}
									className="mb-3"
									secondary
								/>
							)}
						</FormattedMessage>
						<Button primary onClick={handleSubmit(updatePassword)}>
							Save
						</Button>
					</BlockUi>
				</Modal>
			</Overlay>
		);
	}
}

Settings = withTheme(Settings);

Settings = reduxForm({
	form: 'settings',
	validate: (values) => {
		const errors = {};
		if (validator(values.currentPassword)) {
			if (!passwordValidator(values.currentPassword)) errors.currentPassword = true;
			if (!passwordValidator(values.newPassword)) errors.newPassword = true;
			if (!repeatPasswordValidator(values.repeatPassword, values.newPassword)) errors.repeatPassword = true;
		}
		if (validator(values.vocabulary)) {
			if (values.vocabulary.length < 4 || values.vocabulary.length > 6 || isNaN(values.vocabulary))
				errors.vocabulary = true;
		}
		return errors;
	}
})(Settings);

Settings = connect(
	createStructuredSelector({
		loading: createLoadingSelector([ UPDATE_PASSWORD_REQUEST ]),
		initialValues: (state) => ({ lang: langSelector(state), vocabulary: vocabularySelector(state) })
	}),
	{ closeSettings, updatePassword }
)(Settings);

export default Settings;
