import { combineReducers } from "redux";
import _ from "lodash";
import { LOGIN_SUCCESS } from "../LoginPage/actions";
import { REGISTER_SUCCESS } from "../RegisterPage/actions";
import { LOGOUT, SPEECH_ON, SPEECH_OFF, UPLOADS_RECORD } from "./actions";
import { UPDATE_PASSWORD_SUCCESS } from "../Settings/actions";
import { actionChannel } from "redux-saga/effects";
import { formValueSelector } from 'redux-form';


const loadings = (state = {}, { type }) => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);
  if (!matches) return state;

  const [, name, status] = matches;
  return {
    ...state,
    [`${name}_REQUEST`]: status === "REQUEST",
  };
};

const errorMessages = (state = {}, { type, payload }) => {
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  if (!matches) return state;

  const [, name, status] = matches;
  return {
    ...state,
    [`${name}_REQUEST`]: status === "FAILURE" ? payload : null,
  };
};

const user = (state = {}, { type, payload }) => {
  switch (type) {
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return { ...state, ...payload };
    case LOGOUT:
      return { ...state, token: null, email: null };
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, lang: payload.lang, vocabulary: payload.vocabulary };
    default:
      return state;
  }
};


const speech = (state = false, { type, payload }) => {
  switch (type) {
    case SPEECH_ON:
      return true;
    case SPEECH_OFF:
      return false;
    default:
      return state;
  }
};

export default combineReducers({ loadings, errorMessages, user, speech });

export const createLoadingSelector = (requestNames) => (state) =>
  _(requestNames).some((requestName) =>
    _.get(state, `main.loadings.${requestName}`)
  );

export const createErrorMessageSelector = (requestNames) => (state) =>
  _(requestNames)
    .map((requestName) => _.get(state, `main.errorMessages.${requestName}`))
    .compact()
    .first() || null;

export const tokenSelector = (state) => state.main.user.token;

export const emailSelector = (state) => state.main.user.email;

export const langSelector = (state) => state.main.user.lang;

export const vocabularySelector = (state) => state.main.user.vocabulary;

export const pathnameSelector = (state) => state.router.location.pathname;

export const speechSelector = (state) => state.main.speech;

export const titleSelector = (state) => formValueSelector('edit')(state, 'title') || '';