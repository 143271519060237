import axios from "axios";

const BASE_URL = "https://df3j1qxnjb.execute-api.us-east-1.amazonaws.com/v1";

export const post = (url, data, token) =>
  axios.post(`${BASE_URL}${url}`, data, {
    headers: { "Content-Type": "application/json", "X-Auth-Token": token },
  });

export const get = (url, token) =>
  axios.get(`${BASE_URL}${url}`, { headers: { "X-Auth-Token": token } });

export const del = (url, id, token) =>
  axios.delete(`${BASE_URL}${url}`, {
    headers: { "X-Notes-Id": id, "X-Auth-Token": token },
  });

export const getSignUrlApi = async (url, token) =>
  await axios.get(url, { headers: { "x-auth-token": token } });

export const putAudioFileApi = async (url, data, headers) =>
  await axios.put(url, data, { headers });

export const getUploadsListApi = async (url, token) =>
  await axios.get(url, { headers: { "x-auth-token": token } });
