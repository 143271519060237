import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import Icon from "@mdi/react";
import { mdiDelete, mdiClipboardOutline } from "@mdi/js";
import { reduxForm, Field } from "redux-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import striptags from "striptags";
import BlockUi from "react-block-ui";
import QuillField from "../../components/QuillField";
import TextBox from "./components/TextBox";
import TitleBox from "./components/TitleBox";
import Button from "../../components/Button";
import { initialValuesSelector, textSelector, titleSelector } from "./reducer";
import { saveNote, SAVE_NOTE_REQUEST } from "../NotesPage/actions";
import { deleteNote, DELETE_NOTE_REQUEST, autoSaveNote } from "./actions";
import {
  createLoadingSelector,
  emailSelector,
  speechSelector,
} from "../Main/reducer";
import { speechOn, speechOff } from "../Main/actions";
import TextField from "../../components/TextField";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import config from "../../config";
import ReactGA from "react-ga";

class NoteEditPage extends React.Component {
  state = { fileName: "", uploading: false };
  componentDidMount() {
    this.interval = setInterval(this.autoSave, config.autoSavePeriod);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  autoSave = () => {
    const { match, text, title, autoSaveNote, loading } = this.props;
    if (!loading && match.params.id) autoSaveNote(match.params.id, text, title);
  };

  render() {
    const {
      theme,
      history,
      saveNote,
      deleteNote,
      match,
      text,
      title,
      email,
      loading,
      speech,
      speechOn,
      speechOff,
    } = this.props;
    return (
      <BlockUi
        tag="div"
        blocking={loading}
        className="flex-fill d-flex flex-column"
      >
        <Helmet>
          <title>{title} | VoiceboxMD Web</title>
        </Helmet>
        <React.Fragment>
          <div className="d-flex" style={{ flex: "0 0" }}>
            <Button
              onClick={() => deleteNote(match.params.id)}
              noPadding
              className="mr-3"
            >
              <Icon
                path={mdiDelete}
                size={1}
                color={theme.color.default}
                style={{ marginTop: "-4px" }}
              />
              <div className="ml-1">
                <FormattedMessage id="NotesPage.deleteNote" />
              </div>
            </Button>
            <CopyToClipboard
              text={striptags(text.split("</p>").join("\n"))}
              onCopy={() => toast.info(`Note ${title} copyed!`)}
            >
              <Button style={{ marginLeft: -24 }}>
                <Icon
                  path={mdiClipboardOutline}
                  size={1}
                  color={theme.color.default}
                  style={{ marginTop: -4, marginRight: 4 }}
                />
                <FormattedMessage id="NotePage.copyNote" />
              </Button>
            </CopyToClipboard>
            <Button onClick={history.goBack} className="ml-auto">
              <FormattedMessage id="NoteEditPage.cancel" />
            </Button>
            <Button
              primary
              onClick={() => saveNote(match.params.id, text, title)}
            >
              <FormattedMessage id="NoteEditPage.save" />
            </Button>
          </div>
          <TitleBox>
            <FormattedMessage id="NoteEditPage.title">
              {(msg) => (
                <Field
                  name="title"
                  placeholder={msg}
                  component={TextField}
                  noBorder
                  speech={speech}
                  speechOn={speechOn}
                  speechOff={speechOff}
                />
              )}
            </FormattedMessage>
          </TitleBox>
          <TextBox>
            <FormattedMessage id="NoteEditPage.text">
              {(msg) => (
                <Field
                  name="text"
                  component={QuillField}
                  placeholder={msg}
                  onMicClick={() =>
                    ReactGA.event({
                      category: "dictation_web",
                      action: "mic_click",
                      label: email.replace("@", "[at]"),
                    })
                  }
                  speech={speech}
                  speechOn={speechOn}
                  speechOff={speechOff}
                />
              )}
            </FormattedMessage>
          </TextBox>
        </React.Fragment>
      </BlockUi>
    );
  }
}

NoteEditPage = reduxForm({ form: "edit" })(NoteEditPage);

NoteEditPage = withTheme(NoteEditPage);

NoteEditPage = connect(
  createStructuredSelector({
    initialValues: initialValuesSelector,
    text: textSelector,
    title: titleSelector,
    email: emailSelector,
    speech: speechSelector,
    loading: createLoadingSelector([SAVE_NOTE_REQUEST, DELETE_NOTE_REQUEST]),
  }),
  { saveNote, deleteNote, autoSaveNote, speechOn, speechOff }
)(NoteEditPage);

export default NoteEditPage;
