import styled from 'styled-components';

export default styled.input`
	width: 100%;
	border: none;
	background-color: transparent;
	&:focus {
		outline: none;
	}
	${(props) => (props.focus ? `color: ${props.theme.field.focusBorderColor}` : '')};
	&::placeholder {
		${(props) => (props.focus ? `color: ${props.theme.field.focusBorderColor}` : '')};
	}
`;
