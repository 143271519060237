import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import striptags from 'striptags';
import { toast } from 'react-toastify';
import Icon from '@mdi/react';
import { mdiDelete, mdiPencil, mdiCircle, mdiRotateLeft, mdiClipboardOutline } from '@mdi/js';
import BlockUi from 'react-block-ui';
import Button from '../../components/Button';
import { textSelector, titleSelector } from './reducer';
import { deleteNote, DELETE_NOTE_REQUEST } from '../NoteEditPage/actions';
import { createLoadingSelector } from '../Main/reducer';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import NoteBox from './components/NoteBox';

class NotePage extends React.Component {
	render() {
		const { theme, history, deleteNote, match, text, title, loading } = this.props;
		return (
			<BlockUi tag="div" blocking={loading} className="flex-fill d-flex flex-column">
				<Helmet>
					<title>{title} | VoiceboxMD Web</title>
				</Helmet>
				<React.Fragment>
					<div className="d-flex">
						<Button onClick={() => deleteNote(match.params.id)} noPadding className="mr-3">
							<Icon path={mdiDelete} size={1} color={theme.color.default} style={{ marginTop: '-4px' }} />
							<div className="ml-1">
								<FormattedMessage id="NotesPage.deleteNote" />
							</div>
						</Button>
						<CopyToClipboard
							text={striptags(text.split('</p>').join('\n'))}
							onCopy={() => toast.info(`Note ${title} copyed!`)}
						>
							<Button style={{ marginLeft: -24 }}>
								<Icon
									path={mdiClipboardOutline}
									size={1}
									color={theme.color.default}
									style={{ marginTop: -4, marginRight: 4 }}
								/>
								<FormattedMessage id="NotePage.copyNote" />
							</Button>
						</CopyToClipboard>
						<Button onClick={history.goBack} className="ml-auto" style={{ marginRight: -12 }}>
							<Icon
								path={mdiRotateLeft}
								size={1}
								color={theme.color.default}
								style={{ marginTop: -4, marginRight: 4 }}
							/>
							<FormattedMessage id="NotePage.goBack" />
						</Button>
						<Button onClick={() => history.push('/edit/' + match.params.id)} noPadding>
							<Icon path={mdiPencil} size={1} color={theme.color.default} style={{ marginTop: '-4px' }} />
							<div className="ml-1">
								<FormattedMessage id="NotePage.editNote" />
							</div>
						</Button>
					</div>
					<NoteBox>
						<h3 className="mb-3">
							<Icon path={mdiCircle} size={0.4} color={theme.color.dot} />
							<span className="ml-2 pl-1">{title}</span>
						</h3>
						<div dangerouslySetInnerHTML={{ __html: text }} />
					</NoteBox>
				</React.Fragment>
			</BlockUi>
		);
	}
}

NotePage = withTheme(NotePage);

NotePage = connect(
	createStructuredSelector({
		text: textSelector,
		title: titleSelector,
		loading: createLoadingSelector([ DELETE_NOTE_REQUEST ])
	}),
	{ deleteNote }
)(NotePage);

export default NotePage;
