import uuid from 'uuid/v1';
import moment from 'moment';

export const TOOGLE_SELECT_ALL = 'TOOGLE_SELECT_ALL';
export const TOGGLE_SELECT_BY_ID = 'TOGGLE_SELECT_BY_ID';

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAILURE = 'GET_NOTES_FAILURE';

export const DELETE_NOTES_REQUEST = 'DELETE_NOTES_REQUEST';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_FAILURE = 'DELETE_NOTES_FAILURE';

export const SAVE_NOTE_REQUEST = 'SAVE_NOTE_REQUEST';
export const SAVE_NOTE_SUCCESS = 'SAVE_NOTE_SUCCESS';
export const SAVE_NOTE_FAILURE = 'SAVE_NOTE_FAILURE';

export const UPDATE_SORT_BY = 'UPDATE_SORT_BY';

export const toggleSelectAll = () => ({
	type: TOOGLE_SELECT_ALL
});

export const toggleSelectById = (id) => ({
	type: TOGGLE_SELECT_BY_ID,
	payload: id
});

export const getNotes = () => ({
	type: GET_NOTES_REQUEST
});

export const deleteNotes = () => ({
	type: DELETE_NOTES_REQUEST
});

export const saveNote = (id, text, title) => ({
	type: SAVE_NOTE_REQUEST,
	payload: {
		id: id == null ? uuid() : id,
		date: moment().format('YYYY-MM-DD'),
		text,
		title
	}
});

export const updateSortBy = (payload) => ({
	type: UPDATE_SORT_BY,
	payload
});
