import { OPEN_SETTINGS, CLOSE_SETTINGS } from './actions';

const initialState = {
	isOpen: false
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case OPEN_SETTINGS:
			return { ...state, isOpen: true };
		case CLOSE_SETTINGS:
			return { ...state, isOpen: false };
		default:
			return state;
	}
};

export const isOpenSelector = (state) => state.settings.isOpen;
