import styled, { css } from 'styled-components';
import { field } from '../../utils/css';

const secondary = css`
	border: none;
	background-color: #efefef;
	height: 40px;
`;

export default styled.div`
	${field};
	${(props) => (props.secondary ? secondary : '')};
	border: 1px solid
		${(props) =>
			props.error
				? props.theme.field.errorBorderColor
				: props.focus ? props.theme.field.focusBorderColor : props.theme.field.borderColor};
	@media (min-width: 576px) {
		${(props) => (props.noBorder ? 'border: none;' : '')};
	}
`;
