export default {
  autoSavePeriod: 20000,
  gaId: 'UA-103842262-1',
  conversion: {
    regex: /hypen|semicolon|colon/gi,
    map: {
      hyphen: '-',
      semicolon: ';',
      colon: ':'
    }
  },
  langOptions: [
    { value: 'en-US', label: 'English (United States)' },
    { value: 'en-GB', label: 'English (United Kingdom)' },
    { value: 'en-AU', label: 'English (Australia)' },
    { value: 'en-CA', label: 'English (Canada)' }
  ]
};
