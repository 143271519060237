import styled from 'styled-components';

export default styled.div`
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	.ql-toolbar {
		border: none !important;
		@media (max-width: 575px) {
			padding-left: 0;
			padding-right: 0;
			order: 2;
		}
	}
	.scrollbar-container {
		flex: 1 1 auto;
		display: flex;
		flex-flow: column;
		.quill {
			flex: 1;
			display: flex;
			flex-flow: column;
			.ql-container {
				flex: 1;
				.ql-editor {
					min-height: 59vh;
				}
				.ql-editor.ql-blank::before {
					font-style: normal;
				}
				border: none !important;
				font-size: 14px;
				font-family: 'Montserrat';
				color: ${(props) => props.theme.color.default};
			}
		}
		@media (max-width: 575px) {
			padding: 0;
			order: 1;
		}
	}
`;
