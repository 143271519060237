import React from 'react';
import Select from './Select';

export default ({ input: { value, onChange }, options, ...rest }) => (
	<Select
		value={options.filter((option) => option.value === value)}
		onChange={(option) => onChange(option.value)}
		options={options}
		{...rest}
	/>
);
