import React from 'react';
import { IntlProvider } from 'react-intl';
import getBrowserLocale from './getBrowserLocale';
import locales from '../../locales';

class LanguageProvider extends React.Component {
	render() {
		const { children } = this.props;
		return (
			<IntlProvider
				locale={locales[getBrowserLocale()] ? getBrowserLocale() : 'en'}
				messages={locales[getBrowserLocale()] || locales.en}
				textComponent={React.Fragment}
			>
				{children}
			</IntlProvider>
		);
	}
}

export default LanguageProvider;
