import React from 'react';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import PureInput from '../PureInput';
import SpeechRecognition from 'react-speech-recognition';
import Wrapper from './Wrapper';
import { ThemeConsumer } from 'styled-components';
// import Button from "../Button";
// import { mdiMicrophone } from "@mdi/js";
// import swal from "sweetalert";

class TextField extends React.Component {
	state = {
		focus: false,
		isSpeech: false,
		beforeText: '',
		afterText: ''
	};

	render() {
		const {
			input,
			meta: { touched, error },
			onEnter,
			className,
			noBorder,
			secondary,
			stopListening,
			startListening,
			resetTranscript,
			transcript,
			listening,
			speech,
			speechOn,
			speechOff,
			...rest
		} = this.props;
		const { focus } = this.state;
		return (
			<Wrapper
				focus={focus}
				error={!focus && touched && error}
				className={className}
				noBorder={noBorder}
				secondary={secondary}
			>
				<PureInput
					focus={focus}
					ref={(ref) => (this.inputRef = ref)}
					{...input}
					value={
						this.state.isSpeech ? (
							`${this.state.beforeText}${transcript}${this.state.afterText}`
						) : (
							input.value
						)
					}
					onFocus={() => {
						input.onFocus();
						this.setState({ focus: true });
					}}
					onBlur={() => {
						input.onBlur();
						this.setState({
							focus: false,
							beforeText: input.value.substring(0, this.inputRef.selectionStart),
							afterText: input.value.substring(this.inputRef.selectionStart)
						});
					}}
					autoComplete="off"
					{...rest}
					onKeyDown={(e) => {
						if (e.key === 'Enter' && onEnter != null) onEnter();
					}}
				/>
				<ThemeConsumer>
					{(theme) =>
						!focus &&
						touched &&
						error && (
							<Icon
								path={mdiInformationOutline}
								size={1}
								color={theme.field.errorBorderColor}
								className="ml-2"
							/>
						)}
				</ThemeConsumer>
				{/* {speechOn && (
          <Button
            noPadding
            onClick={() => {
              if (this.state.isSpeech) {
                this.setState({ isSpeech: false });
                stopListening();
                input.onChange(`${this.state.beforeText}${transcript}${this.state.afterText}`);
                speechOff();
              } else {
                if (speech) swal('Please turn off other recording');
                else {
                  speechOn();
                  this.setState({ isSpeech: true, prevValue: input.value });
                  startListening();
                  resetTranscript();
                }
              }
            }}
          >
            <Icon path={mdiMicrophone} size={0.9} color={this.state.isSpeech ? '#d44' : '#444'} />
          </Button>
        )} */}
			</Wrapper>
		);
	}
}

export default SpeechRecognition({ autoStart: false })(TextField);
