export const OPEN_SETTINGS = 'OPEN_SETTINGS';
export const CLOSE_SETTINGS = 'CLOSE_SETTINGS';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const openSettings = () => ({
	type: OPEN_SETTINGS
});

export const closeSettings = () => ({
	type: CLOSE_SETTINGS
});

export const updatePassword = (values) => ({
	type: UPDATE_PASSWORD_REQUEST,
	payload: values
});
