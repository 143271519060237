import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { notesSelector } from '../../containers/NotesPage/reducer';
import { formValueSelector } from 'redux-form';

export const matchSelector = createMatchSelector({ path: '/edit/:id' });

export const initialValuesSelector = createSelector(matchSelector, notesSelector, (match, notes) => ({
	title: match && notes[match.params.id] ? notes[match.params.id].title : '',
	text: match && notes[match.params.id] ? notes[match.params.id].text : ''
}));

export const textSelector = (state) => formValueSelector('edit')(state, 'text') || '';

export const titleSelector = (state) => formValueSelector('edit')(state, 'title') || '';
