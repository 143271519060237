import styled from 'styled-components';

export default styled.div`
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	padding: 18px 5.5%;
	box-shadow: 0 12px 32px rgba(0, 0, 0, 0.1);
	height: 79px;
	@media (max-width: 575px) {
		height: 56px;
	}
`;
