import styled from 'styled-components';

export default styled.div`
	display: flex;
	flex-flow: column;
	flex: 1 1 auto;
	justify-content: center;
	padding: 20px 5.5%;
	box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
	@media (min-width: 576px) {
		padding: 30px 5.5%;
	}
`;
