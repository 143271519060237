import React from 'react';
import { reduxForm, Field } from 'redux-form';
import SearchField from './Field';
import { FormattedMessage } from 'react-intl';

export default reduxForm({ form: 'search' })((props) => (
	<FormattedMessage id="Search.placeholder">
		{(msg) => <Field name="search" component={SearchField} placeholder={msg} {...props} />}
	</FormattedMessage>
));
