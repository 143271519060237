import styled from 'styled-components';

const Note = styled.div`
	position: relative;
	box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.1);
	padding: 20px;
	margin-top: 17px;
	margin-bottom: 3px;
	margin-right: 20px;
	background-color: ${(props) => (props.selected ? props.theme.color.primaryLight : '#ffffff')};
	border-top: ${(props) => (props.selected ? `3px solid ${props.theme.color.primary}` : '3px solid transparent')};
	cursor: pointer;
	user-select: none;
`;

Note.Action = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
`;

Note.Text = styled.div`
	word-break: break-all;
	overflow: hidden;
	height: 84px;
`;

Note.Title = styled.h3`
	word-break: break-all;
	height: 87px;
	overflow: hidden;
	padding-top: 15px;
`;

export default Note;
