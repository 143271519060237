export const LOGOUT = "LOGOUT";
export const SPEECH_ON = "SPEECH_ON";
export const SPEECH_OFF = "SPEECH_OFF";
export const UPLOADS_RECORD = "UPLOADS_RECORD";

export const logout = () => ({
  type: LOGOUT,
});

export const speechOn = () => ({
  type: SPEECH_ON,
});

export const speechOff = () => ({
  type: SPEECH_OFF,
});

export const uploadsRecord = () => ({
  type: UPLOADS_RECORD,
});
