import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';

import GuestBox from '../../components/GuestBox';
import Link from '../../components/Link';
import TextField from '../../components/TextField';
import ButtonField from '../../components/ButtonField';

class LoginPage extends React.Component {
	render() {
		const { history } = this.props;
		return (
			<BlockUi>
				<GuestBox>
					<div className="title mb-4">
						<FormattedMessage id="ForgotPage.title" />
					</div>
					<div className="mb-3 text-black">
						<FormattedMessage id="ForgotPage.desc1" />{' '}
						<span className="font-weight-bold">
							<FormattedMessage id="ForgotPage.desc2" />
						</span>
					</div>
					<FormattedMessage id="LoginPage.email">
						{(msg) => (
							<Field name="email" component={TextField} placeholder={msg} className="mb-3" type="email" />
						)}
					</FormattedMessage>
					<ButtonField className="mb-3">
						<FormattedMessage id="ForgotPage.next" />
					</ButtonField>
					<Link bold onClick={() => history.push('/login')} className="forgot">
						<FormattedMessage id="ForgotPage.return" />
					</Link>
				</GuestBox>
			</BlockUi>
		);
	}
}

LoginPage = reduxForm({
	form: 'forgot',
	validate: (values) => {
		const errors = {};
		if (!values.email) errors.email = true;
		if (!values.password) errors.password = true;
		return errors;
	}
})(LoginPage);

LoginPage = connect()(LoginPage);

export default LoginPage;
