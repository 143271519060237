import styled from 'styled-components';

export default styled.div`
	width: 500px;
	max-width: 100%;
	text-align: center;
	align-self: center;
	margin: auto;
	@media (min-width: 576px) {
		box-shadow: 0 4px 64px 4px rgba(0, 0, 0, 0.15);
		padding: 25px 80px;
	}
	.title {
		font-weight: bold;
		font-size: 16px;
		color: #000000;
	}
	.forgot {
		font-size: 13px;
		color: rgb(130, 130, 130);
	}
`;
